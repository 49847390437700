/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_CategoryDataType_ } from '../models/BaseResponse_CategoryDataType_';
import type { BaseResponse_List_PieDataType_ } from '../models/BaseResponse_List_PieDataType_';
import type { BaseResponse_StatisticDataType_ } from '../models/BaseResponse_StatisticDataType_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnalyzeControllerService {

    /**
     * getInterfaceInvokeCountFor7
     * @returns BaseResponse_CategoryDataType_ OK
     * @throws ApiError
     */
    public static getInterfaceInvokeCountFor7UsingGet(): CancelablePromise<BaseResponse_CategoryDataType_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/analyze/getInterfaceInvokeCountFor7',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getPerInterfaceCount
     * @returns BaseResponse_List_PieDataType_ OK
     * @throws ApiError
     */
    public static getPerInterfaceCountUsingGet(): CancelablePromise<BaseResponse_List_PieDataType_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/analyze/getPerInterfaceCount',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getPerInterfaceLeftCount
     * @returns BaseResponse_List_PieDataType_ OK
     * @throws ApiError
     */
    public static getPerInterfaceLeftCountUsingGet(): CancelablePromise<BaseResponse_List_PieDataType_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/analyze/getPerInterfaceLeftCount',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getStatistic
     * @returns BaseResponse_StatisticDataType_ OK
     * @throws ApiError
     */
    public static getStatisticUsingGet(): CancelablePromise<BaseResponse_StatisticDataType_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/analyze/getStatistic',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
