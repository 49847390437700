<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import message from "@arco-design/web-vue/es/message";
import {AnalyzeControllerService} from "../../../generated";
import * as echarts from "echarts";
interface dataType {
  value: number,
  name: string
}

const data = ref<dataType[]>([])

const getPerInterfaceCount = async () => {
  const res=await AnalyzeControllerService.getPerInterfaceCountUsingGet();
  if (res.code === 0){
    data.value = res.data as any;
  }else {
    message.error("getPerInterfaceCount失败！")
  }
}

const option_column = computed(() => {
  return {
    title: {
      text: '用户接口调用总数对比',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: data.value,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
})

onMounted(async ()=>{
  await getPerInterfaceCount();
  let myChart = echarts.init(document.getElementById('main1'));
  myChart.setOption(option_column.value);
})

</script>

<template>
   <div id="main1" style="height: 420px"></div>
</template>

<style scoped lang="less">

</style>
