<template>
  <div id="app">
    <a-typography-paragraph>
    <a-typography-text type="danger">
      您没有访问该页面的权限，请返回首页或者联系管理员
    </a-typography-text>
    </a-typography-paragraph>
    <a-button type="primary" @click="goHome">返回首页</a-button>
  </div>
</template>

<script setup lang="ts">
import {useRouter} from "vue-router";

const router = useRouter();
// 定义一个函数，用于跳转到首页
const goHome = () => {
  router.push("/");
};
</script>
