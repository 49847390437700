<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import message from "@arco-design/web-vue/es/message";
import {AnalyzeControllerService} from "../../../generated";
import * as echarts from "echarts";

interface dataType {
  value: number,
  name: string
}

const data = ref<dataType[]>([])

const getPerInterfaceLeftCount = async () => {
  const res= await AnalyzeControllerService.getPerInterfaceLeftCountUsingGet();
  if (res.code === 0) {
    data.value = res.data as any;
  } else {
    message.error(res.message as any)
  }
}

const option_column = computed(() => {
  return {
    title: {
      text: '用户接口剩余调用总数',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: data.value
      }
    ]
  }
})

onMounted(async() => {
  await getPerInterfaceLeftCount();
  let myChart = echarts.init(document.getElementById('main3'));
  myChart.setOption(option_column.value);
})
</script>

<template>
  <div id="main3" style="height: 420px"></div>
</template>

<style scoped lang="less">

</style>
