<template>
  <div id="">
    <a-table
        :ref="tableRef"
        :columns="columns"
        :data="dataList"

        :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
        @page-change="onPageChange"
    >
      <template #payTime="{record}">
        {{format(record?.payTime)}}
      </template>
      <template #refundTime="{record}">
        {{format(record?.refundTime)}}
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {
  InterfaceInfoOrderControllerService,
  InterfaceInfoOrderQueryRequest

} from "../../generated";
import message from "@arco-design/web-vue/es/message";
import moment from "moment";


const searchParams = ref<InterfaceInfoOrderQueryRequest>({
  current: 1,
  pageSize: 8,
});

const tableRef = ref();
const dataList = ref([]);
const total = ref(0);
const columns = [
  {
    title: '订单ID',
    dataIndex: 'id',
  },
  {
    title: '接口名称',
    dataIndex: 'interfaceInfoName',
  },
  {
    title: '商品ID',
    dataIndex: 'interfaceInfoProductId',
  },
  {
    title: '规格',
    dataIndex: 'specification',
  },
  {
    title: '价格',
    dataIndex: 'price',
  },
  {
    title: '状态',
    dataIndex: 'status',
  },
  {
    title: '支付时间',
    slotName: 'payTime',
  },
  {
    title: '退款时间',
    slotName: 'refundTime',
  },
];
const format = (time: any) => {
  if(time == null|| time == ""){
    return;
  }
  return moment(time).format('YYYY-MM-DD HH:mm:ss')
}
const loadData = async () => {
  const res = await InterfaceInfoOrderControllerService.getMyOrderPageUsingPost(searchParams.value);

  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = parseInt(res.data.total);
  } else {
    message.error("获取订单信息"+res.message);
  }
}
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
  loadData();
};


onMounted(() => {
  loadData();
});

</script>

<style scoped>

</style>