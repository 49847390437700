<template>
  <div id="">
    <a-form :model="searchParams" layout="inline">
<!--      <a-form-item field="title"  style="min-width: 240px">-->
<!--        <a-input-search style="background-color: white" v-model="searchParams.description" placeholder="请输入商品名称"/>-->
<!--      </a-form-item>-->
<!--        <a-form-item>-->
<!--          <a-button type="primary" @click="loadData">查找商品</a-button>-->
<!--        </a-form-item>-->

        <a-form-item>
          <a-button style="float: right" type="primary" @click="handleClickAdd">添加新的商品</a-button>
          <a-modal v-model:visible="addVisible" title="添加新的商品" @cancel="handleCancelAdd" @before-ok="addInterface(addForm)">
            <a-form :model="addForm">
              <a-select v-model="selectOption" style="margin-left: 100px;margin-bottom:25px;width:80%" placeholder="请选择接口">
                <a-option v-for="item of options" :value="item" :label="item.label" :key="item.value"/>
              </a-select>
              <a-form-item ref="name" label="调用次数" style=""  field="specification">
                <a-input v-model="addForm.specification"/>
              </a-form-item>
              <a-form-item ref="name" label="库存" field="stock">
                <a-input v-model="addForm.stock"/>
              </a-form-item>
              <a-form-item ref="name" label="价格" field="price">
                <a-input v-model="addForm.price"/>
              </a-form-item>
            </a-form>
          </a-modal>
        </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
        :ref="tableRef"
        :columns="columns"
        :data="dataList"

        :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
        @page-change="onPageChange"
    >
    
      <template #optional="{ record }">
        <a-space >
          <a-button size="mini"  type="primary"  @click="handleClickUpdate(record.id)">
            修改
          </a-button>
          <a-modal v-model:visible="updateVisible" title="更新商品" @cancel="handleCancelUpdate" @before-ok="updateInterface(form)">
            <a-form :model="form">
              <a-form-item ref="name" label="商品ID"  field="id">
                <a-input v-model="form.id"/>
              </a-form-item>
              <a-select v-model="selectUpdateOption" style="margin-left: 100px;margin-bottom:25px;width:80%" placeholder="请选择接口">
                <a-option v-for="item of options" :value="item" :label="item.label" :key="item.value"/>
              </a-select>
              <a-form-item ref="name" label="调用次数"  field="specification">
                <a-input v-model="form.specification"/>
              </a-form-item>
              <a-form-item ref="name" label="库存" field="stock">
                <a-input v-model="form.stock"/>
              </a-form-item>
              <a-form-item ref="name" label="价格" field="price">
                <a-input v-model="form.price"/>
              </a-form-item>
            </a-form>
          </a-modal>

          <a-button size="mini"  status="danger"  @click="handleClickDelete">
            删除
          </a-button>
          <a-modal v-model:visible="deleteVisible" @ok="deleteInterface(record.id)" @cancel="handleCancelDelete">
            <template #title>
              删除商品
            </template>
            <div>您确认要删除商品吗,该操作结束后无法恢复</div>
          </a-modal>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {
  InterfaceInfoControllerService,
  InterfaceInfoProductAddRequest,
  InterfaceInfoProductControllerService, InterfaceInfoProductQueryRequest,
  InterfaceInfoProductUpdateRequest,

} from "../../../generated";

import message from "@arco-design/web-vue/es/message";
import {useRouter} from "vue-router";
import moment from "moment/moment";


const format = (time: any) => {
  return moment(time).format('YYYY-MM-DD HH:mm:ss')
}
const searchParams = ref<InterfaceInfoProductQueryRequest>({
  pageSize: 8,
  current: 1,
});
const updateVisible = ref(false);
const deleteVisible=ref(false);
const addVisible=ref(false);



const form = ref<InterfaceInfoProductUpdateRequest>({
      id: 0,
      interfaceInfoId: 0,
      specification:0,
      stock:0,
      price: 0,
    }
);
const addForm = ref<InterfaceInfoProductAddRequest>({
      interfaceInfoId: 0,
      price: 0,
      specification:0,
      stock:0,
}
);


// 商品Table
const tableRef = ref();
const dataList = ref([]);
const total = ref(0);
const columns = ref([
  {
    title: '商品id',
    dataIndex: 'id',
  },
  {
    title: '商品名称',
    dataIndex: 'interfaceInfoName',
  },
  {
    title: '规格(次)',
    dataIndex: 'specification',
  },
  {
    title: '价格(元)',
    dataIndex: 'price',
  },
  {
    title: '库存',
    dataIndex: 'stock',
  },
  {
    title: '操作',
    slotName: "optional",
  },
])

const selectUpdateOption=ref({
  label:"",
  value:0
});
const selectOption = ref();

const options = ref([]);

const getInterfaceOptions= async () => {
  const res=await InterfaceInfoControllerService.getInterfaceIdAndNameUsingGet();
  if (res.code === 0) {
      options.value = res.data as any;
  } else {
    message.error("获取接口列表失败"+res.message);
  }
}


const loadData = async () => {

  const res =await InterfaceInfoProductControllerService.listInterfaceInfoProductByPageUsingPost(searchParams.value);

  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = parseInt(res.data.total);
  } else {
    message.error("获取商品信息失败"+res.message);
  }
  await getInterfaceOptions();
}
const router = useRouter();

const addInterface=async (form: InterfaceInfoProductAddRequest)=>{
     form.interfaceInfoId = selectOption.value.value;
     const res = await InterfaceInfoProductControllerService.addInterfaceInfoProductUsingPost(form);
  if (res.code === 0) {
    message.success("添加商品成功");
    loadData();
  } else {
    message.error("添加商品失败"+res.message);
  }
}

const deleteInterface=async (id: number) =>{
  const res =await InterfaceInfoProductControllerService.deleteInterfaceInfoProductUsingPost({
    id:id
  });
  if (res.code === 0) {
    message.success("删除成功");
    loadData();
  } else {
    message.error("删除失败"+res.message);
  }
}
const updateInterface=async (form : InterfaceInfoProductUpdateRequest) =>{
  form.interfaceInfoId = selectUpdateOption.value.value;
  const res =await InterfaceInfoProductControllerService.updateInterfaceInfoProductUsingPost(form);
  if (res.code === 0) {
    message.success("更新成功");
    loadData();
  } else {
    message.error("更新失败"+res.message);
  }
}


const handleClickDelete = () => {
  deleteVisible.value = true;
};
const handleCancelDelete = () => {
  deleteVisible.value = false;
}
const handleClickAdd = () => {
  addVisible.value = true;
};
const handleCancelAdd = () => {
  addVisible.value = false;
}

const handleClickUpdate =async (id:number) => {
  updateVisible.value = true;
  const res= await InterfaceInfoProductControllerService.getInterfaceInfoProductByIdUsingGet(id);
  if (res.code !== 0) {
    message.error("获取商品信息失败"+res.message);
  }else{
     Object.assign(form.value,res.data);
     selectUpdateOption.value.value=form.value.interfaceInfoId as any;
     selectUpdateOption.value.label=res.data?.interfaceInfoName as string;
  }
  console.log(res.data)
  console.log(form.value);

};
const handleCancelUpdate = () => {
  updateVisible.value = false;
}
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
  loadData();
};

onMounted(() => {
  loadData();
});
</script>

<style scoped>

</style>