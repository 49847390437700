/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_InterfaceInfoOrder_ } from '../models/BaseResponse_InterfaceInfoOrder_';
import type { BaseResponse_List_InterfaceInfoOrder_ } from '../models/BaseResponse_List_InterfaceInfoOrder_';
import type { BaseResponse_long_ } from '../models/BaseResponse_long_';
import type { BaseResponse_Page_InterfaceInfoMyOrderVO_ } from '../models/BaseResponse_Page_InterfaceInfoMyOrderVO_';
import type { BaseResponse_Page_InterfaceInfoOrder_ } from '../models/BaseResponse_Page_InterfaceInfoOrder_';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { InterfaceInfoOrderAddRequest } from '../models/InterfaceInfoOrderAddRequest';
import type { InterfaceInfoOrderPayRequest } from '../models/InterfaceInfoOrderPayRequest';
import type { InterfaceInfoOrderQueryRequest } from '../models/InterfaceInfoOrderQueryRequest';
import type { InterfaceInfoOrderUpdateRequest } from '../models/InterfaceInfoOrderUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InterfaceInfoOrderControllerService {

    /**
     * addInterfaceInfoOrder
     * @param interfaceInfoOrderAddRequest interfaceInfoOrderAddRequest
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addInterfaceInfoOrderUsingPost(
interfaceInfoOrderAddRequest: InterfaceInfoOrderAddRequest,
): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/interfaceInfoOrder/add',
            body: interfaceInfoOrderAddRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * deleteInterfaceInfoOrder
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteInterfaceInfoOrderUsingPost(
deleteRequest: DeleteRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/interfaceInfoOrder/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getInterfaceInfoOrderById
     * @param id id
     * @returns BaseResponse_InterfaceInfoOrder_ OK
     * @throws ApiError
     */
    public static getInterfaceInfoOrderByIdUsingGet(
id?: number,
): CancelablePromise<BaseResponse_InterfaceInfoOrder_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/interfaceInfoOrder/get',
            query: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getMyOrderPage
     * @param pageRequest pageRequest
     * @returns BaseResponse_Page_InterfaceInfoMyOrderVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static getMyOrderPageUsingPost(
pageRequest: InterfaceInfoOrderQueryRequest,
): CancelablePromise<BaseResponse_Page_InterfaceInfoMyOrderVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/interfaceInfoOrder/getMyOrderPage',
            body: pageRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * listInterfaceInfoOrder
     * @param createTime 
     * @param current 
     * @param id 
     * @param interfaceInfoId 
     * @param interfaceInfoProductId 
     * @param pageSize 
     * @param payTime 
     * @param price 
     * @param refundTime 
     * @param sortField 
     * @param sortOrder 
     * @param status 
     * @param userId 
     * @returns BaseResponse_List_InterfaceInfoOrder_ OK
     * @throws ApiError
     */
    public static listInterfaceInfoOrderUsingGet(
createTime?: string,
current?: number,
id?: number,
interfaceInfoId?: number,
interfaceInfoProductId?: number,
pageSize?: number,
payTime?: string,
price?: number,
refundTime?: string,
sortField?: string,
sortOrder?: string,
status?: number,
userId?: number,
): CancelablePromise<BaseResponse_List_InterfaceInfoOrder_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/interfaceInfoOrder/list',
            query: {
                'createTime': createTime,
                'current': current,
                'id': id,
                'interfaceInfoId': interfaceInfoId,
                'interfaceInfoProductId': interfaceInfoProductId,
                'pageSize': pageSize,
                'payTime': payTime,
                'price': price,
                'refundTime': refundTime,
                'sortField': sortField,
                'sortOrder': sortOrder,
                'status': status,
                'userId': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * listInterfaceInfoOrderByPage
     * @param interfaceInfoOrderQueryRequest interfaceInfoOrderQueryRequest
     * @returns BaseResponse_Page_InterfaceInfoOrder_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listInterfaceInfoOrderByPageUsingPost(
interfaceInfoOrderQueryRequest: InterfaceInfoOrderQueryRequest,
): CancelablePromise<BaseResponse_Page_InterfaceInfoOrder_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/interfaceInfoOrder/list/page',
            body: interfaceInfoOrderQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * payInterfaceInfoOrder
     * @param interfaceInfoOrderPayRequest interfaceInfoOrderPayRequest
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static payInterfaceInfoOrderUsingPost(
interfaceInfoOrderPayRequest: InterfaceInfoOrderPayRequest,
): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/interfaceInfoOrder/pay',
            body: interfaceInfoOrderPayRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * updateInterfaceInfoOrder
     * @param interfaceInfoOrderUpdateRequest interfaceInfoOrderUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateInterfaceInfoOrderUsingPost(
interfaceInfoOrderUpdateRequest: InterfaceInfoOrderUpdateRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/interfaceInfoOrder/update',
            body: interfaceInfoOrderUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
