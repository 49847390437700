<template>
  <div id="userLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header" >
        <a-space @click="toIndex" class="header-div">
            <img src="../assets/logo.gif" class="logo" />
            <div class="title">JW-API</div>
        </a-space>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        <GlobalFooter></GlobalFooter>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<style scoped>


.header-div:hover{
  cursor: pointer;
}

#userLayout {
  text-align: center;
  background-repeat: repeat;
  background: url("https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/V-_oS6r-i7wAAAAAAAAAAAAAFl94AQBr");
}

#userLayout .logo {
  width: 64px;
  height: 64px;
}

#userLayout .header {
  margin-top: 16px;
}

#userLayout .content {
  margin-bottom: 16px;
  padding: 20px;
}

#userLayout .footer {
  padding: 16px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
<script setup lang="ts">
import GlobalFooter from "@/components/GlobalFooter";
import {useRouter} from "vue-router";
const router = useRouter();

const toIndex = () => {
  router.push({
    path: `/`,
  });
};
</script>
