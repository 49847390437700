/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_InterfaceInfoProductVO_ } from '../models/BaseResponse_InterfaceInfoProductVO_';
import type { BaseResponse_List_InterfaceInfoProductVO_ } from '../models/BaseResponse_List_InterfaceInfoProductVO_';
import type { BaseResponse_long_ } from '../models/BaseResponse_long_';
import type { BaseResponse_Page_InterfaceInfoProductVO_ } from '../models/BaseResponse_Page_InterfaceInfoProductVO_';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { InterfaceInfoProductAddRequest } from '../models/InterfaceInfoProductAddRequest';
import type { InterfaceInfoProductQueryRequest } from '../models/InterfaceInfoProductQueryRequest';
import type { InterfaceInfoProductUpdateRequest } from '../models/InterfaceInfoProductUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InterfaceInfoProductControllerService {

    /**
     * addInterfaceInfoProduct
     * @param interfaceInfoProductAddRequest interfaceInfoProductAddRequest
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addInterfaceInfoProductUsingPost(
interfaceInfoProductAddRequest: InterfaceInfoProductAddRequest,
): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/InterfaceInfoProduct/add',
            body: interfaceInfoProductAddRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * deleteInterfaceInfoProduct
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteInterfaceInfoProductUsingPost(
deleteRequest: DeleteRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/InterfaceInfoProduct/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getInterfaceInfoProductById
     * @param id id
     * @returns BaseResponse_InterfaceInfoProductVO_ OK
     * @throws ApiError
     */
    public static getInterfaceInfoProductByIdUsingGet(
id?: number,
): CancelablePromise<BaseResponse_InterfaceInfoProductVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/InterfaceInfoProduct/get',
            query: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getByInterfaceId
     * @param interfaceInfoId interfaceInfoId
     * @returns BaseResponse_List_InterfaceInfoProductVO_ OK
     * @throws ApiError
     */
    public static getByInterfaceIdUsingGet(
interfaceInfoId: number,
): CancelablePromise<BaseResponse_List_InterfaceInfoProductVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/InterfaceInfoProduct/getByInterfaceId',
            query: {
                'interfaceInfoId': interfaceInfoId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * listInterfaceInfoProduct
     * @param current 
     * @param id 
     * @param interfaceInfoId 
     * @param pageSize 
     * @param price 
     * @param sortField 
     * @param sortOrder 
     * @param stock 
     * @returns BaseResponse_List_InterfaceInfoProductVO_ OK
     * @throws ApiError
     */
    public static listInterfaceInfoProductUsingGet(
current?: number,
id?: number,
interfaceInfoId?: number,
pageSize?: number,
price?: number,
sortField?: string,
sortOrder?: string,
stock?: number,
): CancelablePromise<BaseResponse_List_InterfaceInfoProductVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/InterfaceInfoProduct/list',
            query: {
                'current': current,
                'id': id,
                'interfaceInfoId': interfaceInfoId,
                'pageSize': pageSize,
                'price': price,
                'sortField': sortField,
                'sortOrder': sortOrder,
                'stock': stock,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * listInterfaceInfoProductByPage
     * @param interfaceInfoProductQueryRequest interfaceInfoProductQueryRequest
     * @returns BaseResponse_Page_InterfaceInfoProductVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listInterfaceInfoProductByPageUsingPost(
interfaceInfoProductQueryRequest: InterfaceInfoProductQueryRequest,
): CancelablePromise<BaseResponse_Page_InterfaceInfoProductVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/InterfaceInfoProduct/list/page',
            body: interfaceInfoProductQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * updateInterfaceInfoProduct
     * @param interfaceInfoProductUpdateRequest interfaceInfoProductUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateInterfaceInfoProductUsingPost(
interfaceInfoProductUpdateRequest: InterfaceInfoProductUpdateRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/InterfaceInfoProduct/update',
            body: interfaceInfoProductUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
