import { RouteRecordRaw } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import UserLoginView from "@/views/user/UserLoginView.vue";
import UserRegisterView from "@/views/user/UserRegisterView.vue";
import NoAuthView from "@/views/NoAuthView.vue";
import HomeView from "@/views/HomeView.vue";
import ViewInterface from "@/views/interface/ViewInterface.vue";
import UserInfo from "@/views/UserInfo.vue";
import ManageInterface from "@/views/interface/ManageInterface.vue";
import AnalyseInterface from "@/views/analyze/AnalyseInterface.vue";
import ManageInterfaceProduct from "@/views/interface/ManageInterfaceProduct.vue";
import ACCESS_ENUM from "@/access/accessEnum";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    name: "用户",
    component: UserLayout,
    children: [
      {
        path: "/user/login",
        name: "用户登录",
        component: UserLoginView,
      },
      {
        path: "/user/register",
        name: "用户注册",
        component: UserRegisterView,
      },
    ],
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/noAuth",
    name: "无权限",
    component: NoAuthView,
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/",
    name: "主页",
    component: HomeView,
  },
  {
    path: "/info",
    name: "个人中心",
    component: UserInfo,
    meta: {
      access: ACCESS_ENUM.USER,
    },
  },
  {
    path: "/analyze",
    name: "接口分析",
    component: AnalyseInterface,
    meta:{
      access: ACCESS_ENUM.USER
    }
  },
  {
    path: "/interface/manage",
    name: "接口管理",
    component: ManageInterface,
    meta: {
      access: ACCESS_ENUM.ADMIN,
      onlyAdmin: true,
    },
  },
  {
    path: "/interface/product",
    name: "商品管理",
    component: ManageInterfaceProduct,
    meta: {
      access: ACCESS_ENUM.ADMIN,
      onlyAdmin: true,
    },
  },
  {
    path: "/interface/view/:id",
    name: "查看接口调用",
    component: ViewInterface,
    props: true,
    meta: {
      access: ACCESS_ENUM.USER,
      hideInMenu: true,
    },
  },
];
