/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_List_UserInterfaceInfo_ } from '../models/BaseResponse_List_UserInterfaceInfo_';
import type { BaseResponse_List_UserInterfaceInfoVO_ } from '../models/BaseResponse_List_UserInterfaceInfoVO_';
import type { BaseResponse_long_ } from '../models/BaseResponse_long_';
import type { BaseResponse_Page_UserInterfaceInfo_ } from '../models/BaseResponse_Page_UserInterfaceInfo_';
import type { BaseResponse_UserInterfaceInfo_ } from '../models/BaseResponse_UserInterfaceInfo_';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { UserInterfaceInfoAddRequest } from '../models/UserInterfaceInfoAddRequest';
import type { UserInterfaceInfoUpdateRequest } from '../models/UserInterfaceInfoUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserInterfaceInfoControllerService {

    /**
     * addUserInterfaceInfo
     * @param userInterfaceInfoAddRequest userInterfaceInfoAddRequest
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addUserInterfaceInfoUsingPost(
userInterfaceInfoAddRequest: UserInterfaceInfoAddRequest,
): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/userInterfaceInfo/add',
            body: userInterfaceInfoAddRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * deleteUserInterfaceInfo
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteUserInterfaceInfoUsingPost(
deleteRequest: DeleteRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/userInterfaceInfo/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getUserInterfaceInfoById
     * @param id id
     * @returns BaseResponse_UserInterfaceInfo_ OK
     * @throws ApiError
     */
    public static getUserInterfaceInfoByIdUsingGet(
id?: number,
): CancelablePromise<BaseResponse_UserInterfaceInfo_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/userInterfaceInfo/get',
            query: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getByUserId
     * @returns BaseResponse_List_UserInterfaceInfoVO_ OK
     * @throws ApiError
     */
    public static getByUserIdUsingGet(): CancelablePromise<BaseResponse_List_UserInterfaceInfoVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/userInterfaceInfo/getByUserId',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * listUserInterfaceInfo
     * @param current 
     * @param id 
     * @param interfaceInfoId 
     * @param leftNum 
     * @param pageSize 
     * @param sortField 
     * @param sortOrder 
     * @param status 
     * @param totalNum 
     * @param userId 
     * @returns BaseResponse_List_UserInterfaceInfo_ OK
     * @throws ApiError
     */
    public static listUserInterfaceInfoUsingGet(
current?: number,
id?: number,
interfaceInfoId?: number,
leftNum?: number,
pageSize?: number,
sortField?: string,
sortOrder?: string,
status?: number,
totalNum?: number,
userId?: number,
): CancelablePromise<BaseResponse_List_UserInterfaceInfo_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/userInterfaceInfo/list',
            query: {
                'current': current,
                'id': id,
                'interfaceInfoId': interfaceInfoId,
                'leftNum': leftNum,
                'pageSize': pageSize,
                'sortField': sortField,
                'sortOrder': sortOrder,
                'status': status,
                'totalNum': totalNum,
                'userId': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * listUserInterfaceInfoByPage
     * @param current 
     * @param id 
     * @param interfaceInfoId 
     * @param leftNum 
     * @param pageSize 
     * @param sortField 
     * @param sortOrder 
     * @param status 
     * @param totalNum 
     * @param userId 
     * @returns BaseResponse_Page_UserInterfaceInfo_ OK
     * @throws ApiError
     */
    public static listUserInterfaceInfoByPageUsingGet(
current?: number,
id?: number,
interfaceInfoId?: number,
leftNum?: number,
pageSize?: number,
sortField?: string,
sortOrder?: string,
status?: number,
totalNum?: number,
userId?: number,
): CancelablePromise<BaseResponse_Page_UserInterfaceInfo_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/userInterfaceInfo/list/page',
            query: {
                'current': current,
                'id': id,
                'interfaceInfoId': interfaceInfoId,
                'leftNum': leftNum,
                'pageSize': pageSize,
                'sortField': sortField,
                'sortOrder': sortOrder,
                'status': status,
                'totalNum': totalNum,
                'userId': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * updateUserInterfaceInfo
     * @param userInterfaceInfoUpdateRequest userInterfaceInfoUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateUserInterfaceInfoUsingPost(
userInterfaceInfoUpdateRequest: UserInterfaceInfoUpdateRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/userInterfaceInfo/update',
            body: userInterfaceInfoUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
