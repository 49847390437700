<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import message from "@arco-design/web-vue/es/message";
import {AnalyzeControllerService} from "../../../generated";
import * as echarts from "echarts";


const nameData = ref<string[]>([])
const valueData = ref<number[]>([])

const getInterfaceInvokeCountFor7 = async () => {
  const res=await AnalyzeControllerService.getInterfaceInvokeCountFor7UsingGet();
  if (res.code === 0){
    nameData.value = res.data?.interfaceInfoName as any;
    valueData.value = res.data?.interfaceInfoCountBy7 as any;
  }else {
    message.error("getPerInterfaceCount失败！")
  }
}

const option_column = computed(() => {
  return {
    title: {
      text: '用户近七次接口调用总数',
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: nameData.value,
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        type: 'bar',
        barWidth: '60%',
        data: valueData.value
      }
    ]
  }
})

onMounted(async () => {
  await getInterfaceInvokeCountFor7();
  let myChart = echarts.init(document.getElementById('main2'));
  myChart.setOption(option_column.value);
})
</script>

<template>
  <div id="main2" style="height: 420px"></div>
</template>

<style scoped lang="less">

</style>
