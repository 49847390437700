<template>
  <div id="">
    <div style="display: flex" >
       <a-space direction="vertical" >

         <a-space :size="60">
           <a-card title="个人信息" style="width: 100%;height:270px">
             <a-list size="medium" >
               <a-list-item>
                 <icon-user />
                 用户名称: {{user?.userName??"游客"}}
               </a-list-item>
               <a-list-item>
                 <icon-info-circle />
                 用户账号: {{user?.userAccount}}
               </a-list-item>

               <a-list-item>
                 <icon-user-group />
                 用户角色: {{user?.userRole}}
               </a-list-item>
               <a-list-item>
                 <icon-clock-circle />
                 注册时间: {{format(user?.createTime)}}
               </a-list-item>
             </a-list>
           </a-card>

           <a-card title="开发者密钥" style="width: 100%;height:270px">
             <template #extra>
               <a-button type="primary" @click="reGenerate">
                 重新生成
               </a-button>
             </template>
             <a-list size="medium" >
               <a-list-item >
                 <icon-lock /> accessKey:
                 <a-typography-paragraph copyable>
                   {{devKey?.accessKey}}
                 </a-typography-paragraph>
               </a-list-item>
               <a-list-item >
                 <icon-lock /> secretKey:
                 <a-typography-paragraph copyable>
                   {{devKey?.secretKey}}
                 </a-typography-paragraph>
               </a-list-item>
             </a-list>
           </a-card>
         </a-space>
         <a-card  title="开发者SDK"  style="width: 100%;margin-top: 30px" hoverable>
           <template #extra>
             <a-link>使用说明</a-link>
           </template>
           <a-button type="primary">
             <template #icon>
               <icon-download />
             </template>
             <template #default>JAVA SDK</template>
           </a-button>
         </a-card>
       </a-space>
       <a-card title="可调用接口" style="width: 38%;margin-left: 50px">
         <a-table :pagination="false" :ref="tableRef" :data="dataSource" :columns="columns" >
         <template #optional="{ record }">
           <a-button type="primary" @click="goToInvokeInterface(record.interfaceInfoId)">前往调用</a-button>
         </template>
         </a-table>
       </a-card>
  </div>
    <a-card title="我的订单" style="width:100%;margin-top: 15px">
       <OrderTable></OrderTable>
    </a-card>

  </div>
</template>

<script setup lang="ts">
// 获取登录用户信息
import {
  DevKeyVO,
  User,
  UserControllerService,
  UserInterfaceInfo,
  UserInterfaceInfoControllerService,
  UserVO
} from "../../generated";
import {computed} from "vue/dist/vue";
import {useStore} from "vuex";
import {onMounted, ref} from "vue";
import message from "@arco-design/web-vue/es/message";
import moment from "moment/moment";
import {useRouter} from "vue-router";
import OrderTable from "@/components/OrderTable.vue";
// 获取存储用户的信息
const store = useStore();

const format = (time: any) => {
  return moment(time).format('YYYY-MM-DD HH:mm:ss')
}



// 可调用接口 Table
const dataSource = ref([])
const tableRef = ref();
const columns = ref(
    [
      {
        title: '接口名称',
        dataIndex: 'interfaceInfoName',
      },
      {
        title: '总调用次数',
        dataIndex: 'totalNum',
      },
      {
        title: '剩余调用次数',
        dataIndex: 'leftNum',
      },
      {
        title: '操作',
        slotName: "optional",
      },
    ],
)
const getInterfaceByUserId = async () => {
  const res =await UserInterfaceInfoControllerService.getByUserIdUsingGet();
  if (res.code === 0){
    dataSource.value = res.data as any;
  }else {
    message.error(res.message as any)
  }
}
const router = useRouter();
const goToInvokeInterface = (id : number)=>{
  router.push("/interface/view/"+id);
}

const user=ref<User>();
const devKey=ref<DevKeyVO>();

// 重新生成ak/sk
const reGenerate=async ()=>{
  const res=await UserControllerService.updateKeyUsingPost();
  if (res.code === 0) {
    devKey.value=res.data;
    message.success("重新生成密钥成功");
  } else {
    message.error(res.message as any);
  }
}

const getUser=async ()=> {
  const res=await UserControllerService.getUserByIdUsingGet(store.state.user?.loginUser.id as any);

  if (res.code === 0) {
    user.value=res.data;
  } else {
    message.error(res.message as any);
  }

}

const getKey=async ()=>{
  const result=await UserControllerService.getKeyUsingGet();

  if (result.code === 0) {

    devKey.value=result.data;
  } else {
    message.error(result.message as any);
  }
}

onMounted(()=>{
   getUser();
   getKey();
   getInterfaceByUserId();
})

</script>

<style scoped>

</style>