<script setup lang="ts">

import {computed, onMounted, ref} from "vue";
import message from "@arco-design/web-vue/es/message";
import {AnalyzeControllerService} from "../../../generated";

const statisticData = ref<{
  shopInterfaceCount: number,
  invokeInterfaceTotalCount: number,
  invokeInterfaceLeftCount: number,
  invokeMoreInterface: string
}>({
  shopInterfaceCount: 0,
  invokeInterfaceTotalCount: 0,
  invokeInterfaceLeftCount: 0,
  invokeMoreInterface: "XXX"
})
const data = ref([{
  label: '接口名称:',
  value: '暂无接口',
  span: 3,
}
]);

const getStatistic = async () => {
  const res = await AnalyzeControllerService.getStatisticUsingGet();
  if (res.code === 0){
    statisticData.value = res.data as any;
    data.value[0].value = statisticData.value.invokeMoreInterface;
  }else {
    message.error(res.message as any)
  }
}

onMounted(() => {
  getStatistic()
})
</script>

<template>
  <a-card title="数值统计">
    <a-row>
      <a-col :span="6">
        <a-statistic title="已购买接口数量" :value="statisticData.shopInterfaceCount" style="margin-right: 50px"/>
      </a-col>
      <a-col :span="6">
        <a-statistic title="接口总调用次数" :value="statisticData.invokeInterfaceTotalCount"/>
      </a-col>
      <a-col :span="6">
        <a-statistic title="剩余总调用次数" :value="statisticData.invokeInterfaceLeftCount" style="margin-right: 50px"/>
      </a-col>
      <a-col :span="6">
        <a-descriptions :data="data" title="调用次数最多的接口" layout="inline-horizontal" />
      </a-col>
    </a-row>
  </a-card>
</template>

<style scoped lang="less">

</style>
