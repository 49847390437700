<template>
  <div id="">
    <a>
      <div class="font-back">
       Copyright © 2022-2024
        <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left:10px;color:black; text-decoration: none" >粤ICP备2022140815号-1</a>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>