<template>
  <div id="">
    <a-form :model="searchParams" layout="inline">
      <a-form-item field="title"   style="min-width: 240px">
        <a-input-search style="background-color: white" v-model="searchParams.description" placeholder="请输入接口名称"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="loadData">查找接口</a-button>
      </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
        :ref="tableRef"
        :columns="columns"
        :data="dataList"

        :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
        @page-change="onPageChange"
    >
      <template #status="{ record }">
          <a-tag v-if="record.status===1" color="green">
            在线
          </a-tag>
          <a-tag v-if="record.status===0" color="grey">
            离线
          </a-tag>
      </template>
      <template #type="{record}">
       <a-tag v-if="record.type==0" color="green">
           免费
       </a-tag>
        <a-tag v-if="record.type==1" color="orange">
           付费
        </a-tag>
      </template>
      <template #createTime="{record}">
         {{format(record?.createTime)}}
      </template>
      <template #optional="{ record }">
        <div style="text-align: right">
          <a-space>
            <a-button  type="primary"  @click="toViewPage(record.id)">
              查看文档
            </a-button>
<!--            <a-button  type="primary" v-if="record.type===1"  @click="addCount(record.id)">-->
<!--              <template #icon>-->
<!--                <icon-plus />-->
<!--              </template>-->
<!--               调用次数-->
<!--            </a-button>-->
<!--            <a-button type="text" status="success" v-if="record.type===0">-->
<!--              <template #icon>-->
<!--                <icon-check />-->
<!--              </template>-->
<!--              免费调用-->
<!--            </a-button>-->
          </a-space>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {InterfaceInfo, InterfaceInfoControllerService, InterfaceInfoQueryRequest} from "../../generated";

import message from "@arco-design/web-vue/es/message";
import {useRouter} from "vue-router";
import moment from "moment/moment";


const format = (time: any) => {
  return moment(time).format('YYYY-MM-DD HH:mm:ss')
}
const searchParams = ref<InterfaceInfoQueryRequest>({
  pageSize: 8,
  current: 1,
  description: "",
  sortField: "status",
  sortOrder: "descend"
});

const tableRef = ref();
const dataList = ref([]);
const total = ref(0);
// const interfaceInfo = ref<InterfaceInfo>({
//   id: 0,
//   name: 'XX',
//   description: 'XX',
//   url: 'XX',
//   requestParams: '{XX}',
//   requestHeader: '{XX}',
//   responseHeader: '{XX}',
//   status: 0,
//   method: 'XX',
//   userId: 0,
//   createTime: 'XX',
//   updateTime: 'XX'
// })

const columns = [
  {
    title: "接口名称",
    dataIndex: "name",
  },
  {
    title: "接口介绍",
    dataIndex: "description",
  },
  {
    title: "状态",
    slotName: "status",
  },
  {
    title: "类型",
    slotName: "type",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
  {
    slotName: "optional",
  },
];

const loadData = async () => {

  const res =await InterfaceInfoControllerService.listInterfaceInfoByPageUsingPost(searchParams.value);

  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("获取接口信息失败"+res.message);
  }
}
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
  loadData();
};
onMounted(() => {
  loadData();
});

const router = useRouter();
const toViewPage=(id:any)=>{
  router.push({
    path: '/interface/view/'+id
  });
}


</script>

<style scoped>

</style>