<template>
  <div id="">

    <a-space>
      <a-card title="查看接口文档" class="interface_info">
        <a-descriptions style="margin-top: 20px;" :data="data" :size="size" title="" :column="1"/>
      </a-card>

      <a-tabs style="margin-left:50px">
        <a-tab-pane key="1">
          <template #title>
            <icon-calendar/> 在线测试
          </template>
          <a-card  class="interface_test">

            <a-form  :model="dynamicValidateForm"  @submit-success="doSubmit">

              <a-space
                  v-for="(param, index) in dynamicValidateForm.params"
                  :key="index"
                  style="display: flex; margin-bottom: 8px"
                  size="large"
                  align="baseline"

              >
                <a-form-item
                    :field="`params[${index}].paramKey`"
                    :rules="{
          required: true,
          message: '请填写参数key',
        }"
                >
                  <a-input v-model="param.paramKey" :style="{width:'150px'}" placeholder="参数Key"/>
                </a-form-item>
                <a-form-item
                    :field="`params[${index}].paramValue`"
                    :rules="{
          required: true,
          message: '请填写参数Value',
        }"
                >
                  <a-input v-model="param.paramValue" :style="{width:'250px'}" placeholder="参数Value"/>
                </a-form-item>

                <a-button @click="removeParam(param)">
                  删除
                </a-button>
              </a-space>

              <a-form-item>
                <a-space   align="baseline" :size="50">
                  <a-button type="dashed" status="warning" style="margin-left: 200px" block @click="addParam">
                    添加参数
                  </a-button>
                  <a-button html-type="submit"  type="primary">点击调用</a-button>
                </a-space>
              </a-form-item>


            </a-form>
            <a-spin dot style="text-align: center;margin-left:70px;width:200px" :loading="loading" tip="请勿重复点击调用,耐心等待,如长时间未响应请重试...">
              <a-space></a-space>
            </a-spin>
            <a-card title="调用返回结果" style="min-height: 150px;max-height: 350px;overflow: auto">

              <span>
                 <vue-json-pretty  :deep="1"  :data="invokeResult" :showLine="true"
                 :showSelectController="true" :showLength="true" />
              </span>
            </a-card>

          </a-card>
        </a-tab-pane>
        <a-tab-pane key="2" :disabled="type">
          <template #title>
            <icon-star /> 购买
          </template>
          <a-card class="interface_test">
            <a-table
                :ref="tableRef"
                :data="interfaceInfoProductList"
                :columns="interfaceInfoProductColumns"
                :pagination="false"
             >
              <template #optional="{ record }">
                 <a-button @click="handleClickBuy(record)" type="primary">购买</a-button>
                <a-modal v-model:visible="payVisible" title="选择支付方式"  @ok="cancelPay" @cancel="cancelPay">
                       <a-space style="margin-left:100px" :size="100">
                         <a-button type="primary" @click="payOrder">支付宝</a-button>
                         <a-button status="success" @click="payOrder">微信支付</a-button>
                       </a-space>
                </a-modal>
              </template>
            </a-table>
          </a-card>
        </a-tab-pane>
      </a-tabs>

    </a-space>

  </div>
</template>

<script setup lang="ts">
import {onMounted, ref, watchEffect, withDefaults, defineProps, reactive} from "vue";
import {
  InterfaceInfo,
  InterfaceInfoControllerService,
  InterfaceInfoInvokeRequest, InterfaceInfoOrderAddRequest,
  InterfaceInfoOrderControllerService, InterfaceInfoOrderPayRequest
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import moment from "moment";
import {InterfaceInfoProductControllerService} from "../../../generated/services/InterfaceInfoProductControllerService";

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
interface Props {
  id: string;
}
const props = withDefaults(defineProps<Props>(), {
  id: () => "",
});
const payVisible=ref(false);
const interfaceInfo = ref<InterfaceInfo>();
const size = ref('large');
// GET表单
interface Param {
  paramKey: string;
  paramValue: string;
  id: number;
}
const dynamicValidateForm = reactive<{ params: Param[] }>({
  params: [],
});
const loading =ref(false);
const invokeResult = ref();
let type=ref(false);
// 商品Table
const tableRef = ref();
const interfaceInfoProductList = ref([]);
const interfaceInfoProductColumns = ref([
  {
    title: '接口id',
    dataIndex: 'interfaceInfoId',
  },
  {
    title: '接口名称',
    dataIndex: 'interfaceInfoName',
  },
  {
    title: '规格(次)',
    dataIndex: 'specification',
  },
  {
    title: '价格(元)',
    dataIndex: 'price',
  },
  {
    title: '库存',
    dataIndex: 'stock',
  },
  {
    title: '操作',
    slotName: "optional",
  },
])

const handleClickBuy=(record : any)=>{
  payVisible.value = true;
  addOrderData.value.interfaceInfoProductId = record.id
  addOrderData.value.interfaceInfoId = record.interfaceInfoId
}
const cancelPay=()=>{
  payVisible.value= false;
}
// 购买
const addOrderData = ref<InterfaceInfoOrderAddRequest>({
  interfaceInfoProductId: 0,
  interfaceInfoId: 0
})


const listProduct=async ()=>{
    const res=await InterfaceInfoProductControllerService.getByInterfaceIdUsingGet(props.id as any);
    if (res.code === 0) {
    interfaceInfoProductList.value = res.data as any;
    console.log(interfaceInfoProductList.value);
    }else{
      message.error("获取接口商品失败");
    }
}

const payOrderData = ref<InterfaceInfoOrderPayRequest>({
  id: 0,
  interfaceInfoId: 0,
  interfaceInfoProductId: 0
})
// 下单 + 支付
const payOrder=async()=>{

  const res =await InterfaceInfoOrderControllerService.addInterfaceInfoOrderUsingPost(addOrderData.value)
  if (res.code !== 0) {
    message.error("创建订单失败");
    return;
  }
  payOrderData.value.id=res.data;
  payOrderData.value.interfaceInfoId=addOrderData.value.interfaceInfoId;
  payOrderData.value.interfaceInfoProductId=addOrderData.value.interfaceInfoProductId;
  const PayResult = await InterfaceInfoOrderControllerService.payInterfaceInfoOrderUsingPost(payOrderData.value);
  if(PayResult.code !==0){
    message.error("支付失败");
    return;
  }
  message.success("支付成功");
  loadData();
}


const removeParam = (item: Param) => {
  const index = dynamicValidateForm.params.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.params.splice(index, 1);
  }
};
const addParam = () => {
  dynamicValidateForm.params.push({
    paramKey: '',
    paramValue: '',
    id: Date.now(),
  });
};

// 定义一个转换函数
const convert = (arr:any)=>{
  let kvArr = arr.map ((item: { paramKey: any; paramValue: any; })  => [item.paramKey, item.paramValue]); // kvArr是一个键值对数组，它的值是[["name", "123"]]
  let obj = Object.fromEntries (kvArr); // obj是一个对象，它的属性是kvArr的元素，它的值是{"name": "123"}
  return JSON.stringify (obj); // json是一个JSON字符串
}
const isJson=(str:string)=>{
  let obj = null;
  try{
    obj = eval("(" + str + ")");
  }catch(e){
    console.log(e);
  }
  let xy = Object.prototype.toString.call(obj);
  if (xy == "[object Object]" || xy == "[object Array]") {
    return true;
  } else {
    return false;
  }
}
const doSubmit =async (values : any) =>{

  if(!props.id){
    message.error("接口不存在");
    return;
  }

  loading.value = true

  //转换参数
  const param=convert(dynamicValidateForm.params);
   // console.log(param);

  const res=await InterfaceInfoControllerService.invokeInterfaceInfoUsingPost({
        id:props.id as any,
        userRequestParams:param
  });

  if (res.code === 0) {
    invokeResult.value = res.data;
    if(isJson(invokeResult.value)){
       invokeResult.value=JSON.parse(invokeResult.value);
    }
    message.success("调用成功!");
  } else {
    invokeResult.value = res.message;
    message.error(res.message);
  }
  loading.value = false;
}

const data = ref([
  {
    label: 'id',
    value: '0'
  },
  {
    label: '接口状态',
    value: '开启'
  },
  {
    label: '接口描述',
    value: 'xxx'
  }
  , {
    label: '接口地址',
    value: 'xxx',
  }, {
    label: '请求方法',
    value: 'xxx',
  }, {
    label: '请求参数',
    value: '无'
  },
  {
    label: '请求参数示例',
    value: '无'
  },
  {
    label: '请求头',
    value: '无',
  },
  {
    label: '响应参数',
    value: '无'
  },
  {
    label: '创建时间',
    value: 'xxxx-xx-xx'
  }, {
    label: '更新时间',
    value: 'xxxx-xx-xx'
  },
]);



const loadData = async () => {
  loading.value = false;
  const res = await InterfaceInfoControllerService.getInterfaceInfoByIdUsingGet(
      props.id as any
  );
  interfaceInfo.value=res.data
  if (res.code === 0) {
    for (let item of data.value) {
      // 根据label的值来匹配相应的属性
      switch (item.label) {
        case "id":
          item.value = interfaceInfo.value?.id as any;
          break;
        case "接口状态":
          item.value = interfaceInfo.value?.status === 1 ? "开启" : "关闭"  as any;
          break;
        case "接口描述":
          item.value = interfaceInfo.value?.description  as any;
          break;
        case "接口地址":
          item.value = interfaceInfo.value?.url  as any;
          break;
        case "请求方法":
          item.value = interfaceInfo.value?.method  as any;
          break;
        case "请求参数":
          item.value = interfaceInfo.value?.requestParams || "无"  as any;
          break;
        case "请求参数示例":
          item.value = interfaceInfo.value?.requestExample || "无"  as any;
          break;
        case "请求头":
          item.value = interfaceInfo.value?.requestHeader || "无"  as any;
          break;
        case "响应参数":
          item.value = interfaceInfo.value?.responseParams || "无"  as any;
          break;
        case "创建时间":
          item.value = format(interfaceInfo.value?.createTime)  as any;
          break;
        case "更新时间":
          item.value = format(interfaceInfo.value?.updateTime)  as any;
          break;
        default:
          item.value = "无";
          break;
      }
    }
    if(interfaceInfo.value?.requestExample){
      const param =  JSON.parse(interfaceInfo.value?.requestExample as string);
      for (let paramKey in param) {
        dynamicValidateForm.params.push({
          paramKey: paramKey,
          paramValue: param[paramKey],
          id: Date.now(),
        });
    }
    }
  } else {
    message.error("加载失败，" + res.message);
  }
  // 免费的话，直接禁用购买
  if(interfaceInfo.value?.type===0){
    type.value=true;
  }
  await listProduct();
};
const format = (time: any) => {
  return moment(time).format('YYYY-MM-DD HH:mm:ss')
}
onMounted(() => {
  loadData();
});
</script>

<style scoped>
.interface_info {
  margin-top:60px;
  width: 600px;
  height: 70vh;
}
.interface_test{

  width: 600px;
  height: 70vh;
}


</style>