<template>
  <div id="">
    <a-form :model="searchParams" layout="inline">
      <a-form-item field="title"  style="min-width: 240px">
        <a-input-search style="background-color: white" v-model="searchParams.description" placeholder="请输入接口名称"/>
      </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="loadData">查找接口</a-button>
        </a-form-item>
        <a-form-item>
          <a-button style="float: right" type="primary" @click="handleClickAdd">添加新的接口</a-button>
          <a-modal v-model:visible="addVisible" title="添加新的接口" @cancel="handleCancelAdd" @before-ok="addInterface(addForm)">
            <a-form :model="addForm">
              <a-form-item ref="name" label="接口名称" field="name">
                <a-input v-model="addForm.name"/>
              </a-form-item>
              <a-form-item label="接口描述" field="description">
                <a-textarea v-model="addForm.description"/>
              </a-form-item>
              <a-form-item ref="url" label="接口地址" field="url">
                <a-input v-model="addForm.url" />
              </a-form-item>
              <a-form-item label="请求参数" field="requestParams">
                <a-textarea v-model="addForm.requestParams"/>
              </a-form-item>
              <a-form-item label="请求头" field="requestHeader">
                <a-textarea v-model="addForm.requestHeader"/>
              </a-form-item>
              <a-form-item label="请求示例" field="requestExample">
                <a-textarea v-model="addForm.requestExample"/>
              </a-form-item>
              <a-form-item label="响应头" field="responseHeader">
                <a-textarea v-model="addForm.responseHeader"/>
              </a-form-item>
              <a-form-item label="响应参数" field="responseParams">
                <a-textarea v-model="addForm.responseParams"/>
              </a-form-item>
              <a-form-item label="请求方法" fielde="method">
                <a-select v-model="addForm.method" placeholder="请选择请求方法">
                  <a-option value="GET">GET</a-option>
                  <a-option value="POST">POST</a-option>
                  <a-option value="DELETE">DELETE</a-option>
                  <a-option value="UPDATE">UPDATE</a-option>
                </a-select>
              </a-form-item>
            </a-form>
          </a-modal>
        </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
        :ref="tableRef"
        :columns="columns"
        :data="dataList"

        :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
        @page-change="onPageChange"
    >
      <template #method="{record}">
        <a-tag color="green">
          {{record.method}}
        </a-tag>
      </template>
      <template #type="{record}">
        <a-tag color="green">
          {{record?.type==0?"免费":"付费"}}
        </a-tag>
      </template>
      <template #status="{ record }">
        <a-tag v-if="record.status===1" color="green">
            在线
        </a-tag>
        <a-tag v-if="record.status===0" color="grey">
            离线
        </a-tag>
      </template>
      <template #optional="{ record }">
        <a-space >
          <a-button size="mini"  type="primary"  @click="handleClickUpdate(record.id)">
            修改
          </a-button>
          <a-modal v-model:visible="updateVisible" title="更新接口" @cancel="handleCancelUpdate" @before-ok="updateInterface(form)">
            <a-form :model="form">
              <a-form-item ref="name" label="接口名称" field="name">
                <a-input v-model="form.name"/>
              </a-form-item>
              <a-form-item label="接口描述" field="description">
                <a-textarea v-model="form.description"/>
              </a-form-item>
              <a-form-item ref="url" label="接口地址" field="url">
                <a-input v-model="form.url" />
              </a-form-item>
              <a-form-item label="请求参数" field="requestParams">
                <a-textarea v-model="form.requestParams"/>
              </a-form-item>
              <a-form-item label="请求头" field="requestHeader">
                <a-textarea v-model="form.requestHeader"/>
              </a-form-item>
              <a-form-item label="请求示例" field="requestExample">
                <a-textarea v-model="form.requestExample"/>
              </a-form-item>
              <a-form-item label="响应头" field="responseHeader">
                <a-textarea v-model="form.responseHeader"/>
              </a-form-item>
              <a-form-item label="响应参数" field="responseParams">
                <a-textarea v-model="form.responseParams"/>
              </a-form-item>
              <a-form-item label="请求方法" fielde="method">
                <a-select v-model="form.method" placeholder="请选择请求方法">
                  <a-option value="GET">GET</a-option>
                  <a-option value="POST">POST</a-option>
                  <a-option value="DELETE">DELETE</a-option>
                  <a-option value="UPDATE">UPDATE</a-option>
                </a-select>
              </a-form-item>
            </a-form>
          </a-modal>

          <template  v-if="record.status === 0">
            <a-button size="mini"  status="success" @click="handleOnOrOff(record.id, record.status)">上线</a-button>
          </template>
          <template v-else-if="record.status === 1">
            <a-button size="mini"  status="danger" @click="handleOnOrOff(record.id, record.status)">下线</a-button>
          </template>

          <a-button size="mini"  status="danger"  @click="handleClickDelete">
            删除
          </a-button>
          <a-modal v-model:visible="deleteVisible" @ok="deleteInterface(record.id)" @cancel="handleCancelDelete">
            <template #title>
              删除接口
            </template>
            <div>您确认要删除接口吗,该操作结束后无法恢复</div>
          </a-modal>

        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {
  InterfaceInfo, InterfaceInfoAddRequest,
  InterfaceInfoControllerService,
  InterfaceInfoQueryRequest,
  InterfaceInfoUpdateRequest
} from "../../../generated";

import message from "@arco-design/web-vue/es/message";
import {useRouter} from "vue-router";
import moment from "moment/moment";


const format = (time: any) => {
  return moment(time).format('YYYY-MM-DD HH:mm:ss')
}
const searchParams = ref<InterfaceInfoQueryRequest>({
  pageSize: 8,
  current: 1,
  description: "",
});
const updateVisible = ref(false);
const deleteVisible=ref(false);
const addVisible=ref(false);
const tableRef = ref();
const dataList = ref([]);
const total = ref(0);
const form = ref<InterfaceInfoUpdateRequest>({
      id: 0,
      name: '',
      description: '',
      url: '',
      requestParams: '{}',
      requestExample: '{}',
      requestHeader: '{}',
      responseHeader: '{}',
      responseParams: '{}',
      type: 0,
      status: 0,
      method: ''
}
);
const addForm = ref<InterfaceInfoAddRequest>({
      name: '',
      description: '',
      url: 'http://119.91.233.144:8123/api',
      requestParams: '{}',
      requestExample: '{}',
      requestHeader: '',
      responseHeader: '',
      responseParams: '',
      method: ''
    }
);


const columns = [
  {
    title: "接口名称",
    dataIndex: "name",
  },
  {
    title: "路径",
    dataIndex: "url",
  },
  {
    title: "请求方法",
    slotName: "method",
  },
  {
    title: "请求参数",
    dataIndex: "requestParams",
  },
  {
    title: "类型",
    slotName: "type",
  },
  {
    title: "状态",
    slotName: "status",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];



const loadData = async () => {

  const res =await InterfaceInfoControllerService.listInterfaceInfoByPageUsingPost(searchParams.value);

  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("获取接口信息失败"+res.message);
  }
}
const router = useRouter();
const toViewPage=(id:any)=>{
  router.push({
    path: '/interface/view/'+id
  });
}
const addInterface=async (form: InterfaceInfoAddRequest)=>{
     const res = await InterfaceInfoControllerService.addInterfaceInfoUsingPost(form);
  if (res.code === 0) {
    message.success("添加接口成功");
    loadData();
  } else {
    message.error("添加接口失败"+res.message);
  }
}

const deleteInterface=async (id: number) =>{
  const res =await InterfaceInfoControllerService.deleteInterfaceInfoUsingPost({
    id:id
  });
  if (res.code === 0) {
    message.success("删除成功");
    loadData();
  } else {
    message.error("删除失败"+res.message);
  }
}
const updateInterface=async (form : InterfaceInfoUpdateRequest) =>{
  const res =await InterfaceInfoControllerService.updateInterfaceInfoUsingPost(form);
  if (res.code === 0) {
    message.success("更新成功");
    loadData();
  } else {
    message.error("更新失败"+res.message);
  }

}
const handleOnOrOff=async(id: number, status: number)=>{
     if(status===0){
       const res =await  InterfaceInfoControllerService.onlineInterfaceInfoUsingPost({
          id:id
        })
       if (res.code === 0) {
         message.success("上线成功");
         loadData();
       } else {
         message.error("上线失败"+res.message);
       }
     }else{
       const res =await InterfaceInfoControllerService.offlineInterfaceInfoUsingPost({
         id:id
       })
       if (res.code === 0) {
         message.success("下线成功");
         loadData();
       } else {
         message.error("下线失败"+res.message);
       }
     }
}

const handleClickDelete = () => {
  deleteVisible.value = true;
};
const handleCancelDelete = () => {
  deleteVisible.value = false;
}
const handleClickAdd = () => {
  addVisible.value = true;
};
const handleCancelAdd = () => {
  addVisible.value = false;
}

const handleClickUpdate =async (id:number) => {
  updateVisible.value = true;
  const res= await InterfaceInfoControllerService.getInterfaceInfoByIdUsingGet(id);
  if (res.code !== 0) {
    message.error("获取接口信息失败"+res.message);
  }else{
     Object.assign(form.value,res.data);
  }
  console.log(form.value)

};
const handleCancelUpdate = () => {
  updateVisible.value = false;
}
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
  loadData();
};

onMounted(() => {
  loadData();
});
</script>

<style scoped>

</style>
