<template>
  <div id="userLoginView">
    <div style="margin-top: -10px">
      <span style="color:rgba(0,0,0,.45);"> JW-API 让你轻松调用第三方接口 </span>
    </div>
    <div style="margin-top: 30px;">
      <span class="title">账号密码登录</span>
    </div>
    <a-form
      style="max-width: 360px; margin: 0 auto"
      label-align="left"
      auto-label-width
      :model="form"
      @submit="handleSubmit"
    >
      <a-form-item style="margin-top: 40px" field="userAccount">
        <a-input v-model="form.userAccount" placeholder="请输入账号" size="large" :style="{height:'40px'}" >
        <template #prefix>
          <icon-user />
        </template>
        </a-input>
      </a-form-item>
      <a-form-item style="margin-top: 10px"  field="userPassword" >
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
          size="large"
          :style="{height:'40px'}"
       >
          <template #prefix >
            <icon-lock tooltip="密码不少于 8 位" />
          </template>
        </a-input-password>
      </a-form-item>
      <a-form-item style="margin-top: 25px;margin-left: 45px" >
        <a-button type="primary" html-type="submit" shape="round" style="width: 120px;height:35px">
          登录
        </a-button>
        <a-button type="secondary" status="success" @click="toRegister" shape="round" style="width: 120px;;height:35px; margin-left: 20px;">
          前往注册
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>



<script setup lang="ts">
import { reactive } from "vue";
import { UserControllerService, UserLoginRequest } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

/**
 * 表单信息
 */
const form = reactive({
  userAccount: "",
  userPassword: "",
} as UserLoginRequest);

const router = useRouter();
const store = useStore();

/**
 * 提交表单
 * @param data
 */
const handleSubmit = async () => {
  const res = await UserControllerService.userLoginUsingPost(form);
  // 登录成功，跳转到主页
  if (res.code === 0) {
    await store.dispatch("user/getLoginUser");
    await router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("登陆失败，" + res.message);
  }
};
const toIndex = () => {
  router.push({
    path: `/`,
  });
};

const toRegister = () => {
  router.push({
    path: `/user/register`,
  });
};
</script>

<style  scoped>


</style>